<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <temeno-circle-button
          small
          class="mr-1"
          title="einen Tag zurück"
          @click="prev"
        >
          $arrow_left
        </temeno-circle-button>

        <temeno-button small class="mr-1" @click="setToday">
          Heute
        </temeno-button>

        <temeno-circle-button small title="einen Tag vor" @click="next">
          $arrow_right
        </temeno-circle-button>

        <v-spacer></v-spacer>

        <v-toolbar-title v-if="$refs.calendar" class="mr-3">
          {{ $refs.calendar.title }}
        </v-toolbar-title>

        <v-chip small>KW {{ calendarWeek }}</v-chip>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="48vh">
      <v-calendar
        category-show-all
        event-text-color="black"
        first-interval="0"
        interval-count="24"
        interval-minutes="60"
        locale="de"
        ref="calendar"
        type="category"
        v-model="focus"
        :categories="categories"
        :event-color="getEventColor"
        :events="events"
        :shortWeekdays="false"
        @change="onChange"
        @mousedown:event="dragStart"
        @mouseleave.native="dragCancel"
        @mousemove:time="dragMove"
        @mouseup:time="dragEnd"
      >
        <template v-slot:event="{ event }">
          <div class="px-2 text-left">
            <template v-if="event.conference">
              <strong>{{ event.conference.subject }}</strong>
              <br />
            </template>
            <template v-if="!event.busy">
              {{ getEventTime(event) }}
              <v-icon
                color="black"
                small
                style="position: absolute; top: 3px; right: 4px"
                v-if="event.recurrence"
              >
                mdi-update
              </v-icon>
            </template>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api";

export default {
  name: "PlanningCalendar",
  components: {
    TemenoButton: () => import("@/components/TemenoButton"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    end: Date,
    id: Number,
    participants: Array,
    start: Date,
  },
  data() {
    return {
      drag: {
        event: null,
      },
      events: [],
      period: {
        start: null,
        end: null,
      },
      focus: this.start,
    };
  },
  computed: {
    calendarWeek() {
      if (this.period.start) {
        return moment(this.period.start).week();
      } else {
        return null;
      }
    },
    categories() {
      return [this.userShowName].concat(this.userParticipantNames);
    },
    draftEvents() {
      return this.events.filter((event) => event.draft);
    },
    userParticipantNames() {
      return this.userParticipants.map((p) => p.name);
    },
    userParticipants() {
      return this.participants.filter((p) => p.type === "user");
    },
    userShowName() {
      return this.$store.state.user.showName;
    },
  },
  watch: {
    participants() {
      this.loadEvents();
    },
    start(value) {
      // event start date/time was changed
      this.focus = value;
      this.updateDraftEvents(value, this.end);
    },
    end(value) {
      // event end date/time was changed
      this.updateDraftEvents(this.start, value);
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    dragCancel() {
      this.drag.event = null;
    },
    dragEnd() {
      if (this.drag.event) {
        this.$emit("time-changed", {
          start: new Date(this.drag.event.start),
          end: new Date(this.drag.event.end),
        });
      }
      this.drag.event = null;
    },
    dragMove(tms) {
      if (this.drag.event) {
        const mouse = this.toTime(tms);
        const newStart = this.roundTime(mouse);
        const duration = this.drag.event.end - this.drag.event.start;
        const newEnd = newStart + duration;
        this.updateDraftEvents(newStart, newEnd);
      }
    },
    dragStart({ event }) {
      if (event && event.draft) {
        this.drag.event = event;
      }
    },
    getEventColor(event) {
      if (event.draft) {
        return "primary";
      }
      return "grey lighten-2";
    },
    getEventTime(event) {
      return (
        moment(event.start).format("HH:mm") +
        "–" +
        moment(event.end).format("HH:mm")
      );
    },
    loadEvents() {
      const { start, end } = this.period;
      if (!start || !end) {
        return;
      }
      this.events = [];
      // push current draft event to user's category
      this.events.push({
        draft: true,
        start: this.start,
        end: this.end,
        timed: true,
        category: this.userShowName,
      });
      api
        .getCalendarEvents(start, end)
        .then((response) => {
          response.data
            .filter((event) => event.id !== this.id)
            .map((event) => {
              return {
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
                timed: true,
                category: this.userShowName,
              };
            })
            .forEach((event) => {
              this.events.push(event);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      this.userParticipants.forEach((p) => {
        // push current draft event to each participant's category
        this.events.push({
          draft: true,
          start: this.start,
          end: this.end,
          timed: true,
          category: p.name,
        });
        api
          .getBusyPeriods(p.userId, start, end)
          .then((response) => {
            response.data
              .map((event) => {
                return {
                  busy: true,
                  start: new Date(event.start),
                  end: new Date(event.end),
                  timed: true,
                  category: p.name,
                };
              })
              .forEach((event) => {
                this.events.push(event);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    next() {
      const duration = this.end - this.start;
      const newStart = new Date(this.start.getTime());
      newStart.setDate(this.start.getDate() + 1);
      const newEnd = new Date(newStart.getTime() + duration);
      this.$emit("time-changed", {
        start: newStart,
        end: newEnd,
      });
    },
    onChange() {
      this.period.start = moment(this.focus).toISOString(true);
      this.period.end = moment(this.focus).endOf('day').toISOString(true);
      this.loadEvents();
    },
    prev() {
      const duration = this.end - this.start;
      const newStart = new Date(this.start.getTime());
      newStart.setDate(this.start.getDate() - 1);
      const newEnd = new Date(newStart.getTime() + duration);
      this.$emit("time-changed", {
        start: newStart,
        end: newEnd,
      });
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    setToday() {
      const duration = this.end - this.start;
      const newStart = new Date(this.start.getTime());
      newStart.setDate(new Date().getDate());
      const newEnd = new Date(newStart.getTime() + duration);
      this.$emit("time-changed", {
        start: newStart,
        end: newEnd,
      });
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    updateDraftEvents(start, end) {
      this.draftEvents.forEach((event) => {
        event.start = start;
        event.end = end;
      });
    },
  },
};
</script>

<style>
.v-calendar.v-calendar-category .v-calendar-daily__day {
  cursor: default;
}
.v-calendar.v-calendar-category .v-event-timed {
  cursor: default;
}
.v-calendar.v-calendar-category .v-event-timed.primary {
  cursor: move;
}
</style>
